import { render, staticRenderFns } from "./LowerGradeSettings.vue?vue&type=template&id=38fed944&scoped=true"
import script from "./LowerGradeSettings.vue?vue&type=script&lang=js"
export * from "./LowerGradeSettings.vue?vue&type=script&lang=js"
import style0 from "./LowerGradeSettings.vue?vue&type=style&index=0&id=38fed944&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38fed944",
  null
  
)

export default component.exports